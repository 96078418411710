var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('ag-grid-vue', {
    staticClass: "ag-theme-balham",
    staticStyle: {
      "height": "150px"
    },
    attrs: {
      "columnDefs": _vm.invoicesHeaders,
      "rowData": _vm.subscriptions,
      "suppressCellSelection": true
    },
    on: {
      "cellDoubleClicked": _vm.onCellDoubleClicked
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
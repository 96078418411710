import { defineComponent } from 'vue';
import { dateFormatter } from '@rose/common-ui';
export default defineComponent({
  components: {},
  props: {
    subscriptions: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      rewriteInvoiceDialog: false,
      invoicesHeaders: [{
        headerName: 'Feature',
        width: 120,
        field: 'feature',
        headerTooltip: 'Features',
        flex: true
      }, {
        headerName: 'seit',
        width: 100,
        field: 'start',
        valueFormatter: dateFormatter,
        sort: 'desc'
      }, {
        headerName: 'id',
        width: 250,
        field: 'id',
        cellRenderer: 'ClientsClientIdCell'
      }, {
        headerName: 'Trial start',
        width: 100,
        field: 'trialstart',
        valueFormatter: dateFormatter
      }, {
        headerName: 'Trial end',
        width: 100,
        field: 'trialend',
        valueFormatter: dateFormatter
      }, {
        headerName: 'Gutschein',
        width: 100,
        field: 'gutscheinId',
        cellRenderer: 'BooleanIconCell'
      }, {
        headerName: 'Gekündigt',
        width: 100,
        field: 'cancelledAt',
        cellRenderer: 'BooleanIconCell'
      }]
    };
  },
  watch: {
    subscriptions: function subscriptions(val) {
      console.log('subscriptions', val);
    }
  },
  methods: {
    onCellDoubleClicked: function onCellDoubleClicked(event) {
      var _a, _b;
      console.log('open invoice ID', (_a = event.data) === null || _a === void 0 ? void 0 : _a.id);
      window.open("https://dashboard.stripe.com/subscriptions/".concat((_b = event.data) === null || _b === void 0 ? void 0 : _b.id), '_blank');
    }
  }
});